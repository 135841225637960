import React from 'react';

import PublicLayout from 'pages/PublicLayout';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import {LightTheme} from 'theme/LightTheme';

function App() {
  return (
    <ThemeProvider theme={LightTheme}>
      <CssBaseline />
      <PublicLayout />
    </ThemeProvider>
  );
}

export default App;
