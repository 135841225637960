
export const FORM_LABEL = {
   FIRSTNAME: 'First name',
   LASTNAME: 'Last name',
   USERNAME: 'Username',
   PASSWORD: 'Password',
   PHONE: 'Phone number',
   EMAIL: 'Email',
   INSTITUTION: 'What type of Institutional Investor are you?',
   INSTITUTION_OTHER: 'Please list',
   SUBMIT: 'Submit',
   CLOSE: 'Close'
};


export const MESSAGE = {
   VALIDATION: {
      REQUIRED: 'Required',
      INVALID_FORMAT: 'Format is invalid',
      PASSWORD_MINIMUM: 'Password must be at least 8 characters'
   }
};
