import React from 'react';
import clsx from 'clsx';

import PublicTypography from 'components/PublicTypography';
import Button from '@material-ui/core/Button';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, withStyles, useTheme} from '@material-ui/core/styles';

import xsQBLogo from 'assets/img/logo_xs.png';
import smQBLogo from 'assets/img/logo_sm.png';
import QBLogo from 'assets/img/logo.png';

import quickbondsImage from 'assets/img/qwickbonds.new.png';
import smQuickbondsImage from 'assets/img/qwickbonds_sm.new.png';
import xsQuickbondsImage from 'assets/img/qwickbonds_xs.png';

const useStyles = makeStyles(theme => ({

   banner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      maxWidth: 1248,
      margin: 'auto'
   },
   bannerSection: {
      margin: 'auto 0',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      flexGrow: 1,
      '&:first-child': {
         justifyContent: 'flex-start',
         textAlign: 'left',
         margin: 'auto 0',
         display: 'flex',
         flexGrow: 0
      },
      '&:last-child': {
         textAlign: 'right',
         justifyContent: 'flex-end',
         flexGrow: 0
      }
   },
   contact: {
      fontSize: '0.9rem', 
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('xs')]: {
         display: 'none',
       },
   },
   loginButton: {
      [theme.breakpoints.down('sm')]: {
         display: 'none',
       },
   },
   signupButton: {
      marginRight: 4,
      opacity: 0, 
   },
   signupButtonVisible: {
      animation: 'fadein 500ms ease-in', 
      animationFillMode: 'forwards'
   },
   logoImage: {
      height: 36,
      [theme.breakpoints.down('sm')]: {
         height: 30,
      },
      [theme.breakpoints.down('xs')]: {
         height: 20,
      },
   },
   qbImage: {
      height: 36,
      [theme.breakpoints.down('sm')]: {
         height: 30,
      },
      [theme.breakpoints.down('xs')]: {
         height: 20,
      },
   }
}));

export const SignupButton = withStyles(theme => ({
   root: {
      color: theme.palette.text.selected.primary,
      '&:hover': {
         color: theme.palette.text.selected.primary,
      },
      [theme.breakpoints.down('sm')]: {
         fontSize: '0.75rem',
      },
      [theme.breakpoints.down('xs')]: {
         fontSize: '0.6rem',
      },
   },
 }))(Button);

 export const LoginButton = withStyles(theme => ({
   root: {
      color: theme.palette.text.selected.primary,
      '&:hover': {
         color: theme.palette.text.selected.primary,
      }
   },
 }))(Button);

 

const Logo = () => {
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  if (xsScreen) {
      return <img className={classes.logoImage} style={{marginRight: theme.spacing(1)}} src={xsQBLogo} alt="{Q|B}" />
  } else if (smScreen) {
      return <img className={classes.logoImage} style={{marginRight: theme.spacing(1)}} src={smQBLogo} alt="{Q|B}" />
  } else {
      return <img className={classes.logoImage} style={{marginRight: theme.spacing(1)}} src={QBLogo} alt="{Q|B}" />
  }

}

const QwickBondsImage = () => {
   const theme = useTheme();
   const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
   const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const classes = useStyles();
 
   if (xsScreen) {
       return <img className={classes.qbImage} src={xsQuickbondsImage} alt="QwickBonds" />
   } else if (smScreen) {
       return <img className={classes.qbImage} src={smQuickbondsImage} alt="QwickBonds" />
   } else {
       return <img className={classes.qbImage} src={quickbondsImage} alt="QwickBonds" />
   }
 
 }

const PublicHeader = (props) => {
   const classes = useStyles();
   const {toolbar=false, onClickNewAccount} = props;

   const onLoginClick = () => {
      window.location.href = process.env.REACT_APP_URL; 
   }

   return (
      <div className={classes.banner}>
         <div className={classes.bannerSection}>
            <Logo />
            <QwickBondsImage />
         </div>
         <div className={classes.bannerSection}> 
            <PublicTypography variant='h6' color='primary' className={classes.contact}>844.636.2663</PublicTypography>
         </div>
         <div className={classes.bannerSection}> 
            <SignupButton 
               onClick={onClickNewAccount}
               disabled={!toolbar}
               className={clsx(classes.signupButton, {[classes.signupButtonVisible] : toolbar})}
               color='secondary' 
               variant="contained">Sign Up For Free</SignupButton>          
            <SignupButton 
               onClick={onLoginClick}
               className={classes.loginButton}
               color='primary' 
               variant="contained">Login</SignupButton>
            {/* <LinkButton color='primary' onClick={e => window.open(Constants.LEGACY_URL)} label='Go to Legacy Website' />
            <LinkButton color='primary' onClick={removeUser} label='Logout' />  */}
         </div>
      </div>
   );
};

export default PublicHeader;