import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SearchIcon from '@material-ui/icons/Search';

import { makeStyles, withStyles} from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles(theme => ({
   inputRoot : {
      paddingLeft: 2,
      paddingRight: 2,
      borderRadius: 4,
      background: theme.palette.background.shade,
      '&:hover:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
         borderColor: theme.palette.border.highlight,
         borderWidth: 1,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
         borderWidth: 1,
      },
      '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
         borderColor: theme.palette.border.highlight,
         borderWidth: 1,
      }
   },
   formInputRoot: {
      '&:hover:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
         borderColor: theme.palette.border.primary,
      },
      '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
         borderColor: theme.palette.border.primary,
      }
   },
   notchedOutline: {
      borderColor: theme.palette.border.textInput,
      borderWidth: 1
   },
   input: {
      fontSize: '0.7rem',
      padding: '2px 3px',
      borderRadius: 3,
      fontWeight: 'normal',
      color: theme.palette.text.textInput,
      lineHeight: 1,
   },
   formInput: {
      fontSize: '0.8rem',
      padding: '3px 5px',
   },
   filterInput: {
      textAlign: 'center'
   },
   adornmentPositionStart: {
      marginLeft: 4,
      marginRight: 0,
      fontSize: '0.7rem'
   },
   searchIcon: {
      fontSize: '0.9rem',
      color: fade(theme.palette.common.white, 0.4)
   },
   searchIconPrimary: {
      fontSize: '0.9rem',
      color: theme.palette.primary.main,
   },  
   formControlRoot: {
      width: '100%',
      height: '100%'
   },
   textareaRoot: {
      fontSize: '0.8rem',
      padding: 10,
      maxWidth: 500,
      minHeight: 65,
      height: '100%',
      alignItems: 'start',
      borderRadius: 4,
      background: theme.palette.background.subtle,
      '&:hover .MuiOutlinedInput-notchedOutline': {
         borderColor: theme.palette.border.highlight,
         borderWidth: 1,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
         borderColor: theme.palette.border.highlight,
         borderWidth: 1,
      }
   },
   textareaInput: {
      height: 'inherit',
      '&::-webkit-input-placeholder': { 
         fontStyle: 'italic',
         fontSize: '0.7rem',
       },
       '&:-ms-input-placeholder': { 
         fontStyle: 'italic',
         fontSize: '0.7rem',
       },
       '&::placeholder': {
         fontStyle: 'italic',
         fontSize: '0.7rem',
       }
   },
   sliderRoot: {
      padding: '5px 0',
      maxWidth: 200
   },
   sliderThumb: {
      height: 6,
      width: 4,
      marginLeft: -3,
      marginTop: -2,
      '&:hover': {
         boxShadow: '0px 0px 0px 3px rgba(0, 193, 9, 0.16)'
      },
      '&.MuiSlider-active': {
         boxShadow: '0px 0px 0px 5px rgba(0, 193, 9, 0.16)'
      }
   },
   sliderMark: {
      opacity: 0,
   },
   helperText: {
      fontSize: '0.7rem'
   },
   selectPaper: {
      backgroundColor: theme.palette.background.popperPaper,
   },
   select: {
      fontSize: '0.8rem',
      paddingTop: 4,
      paddingBottom: 0,
      '&:focus': {
         backgroundColor: 'transparent'
      }
   },
   filterselect: {
      fontSize: '0.65rem',
      paddingTop: 0,
      paddingBottom: 0,
      
      '&.MuiSelect-select': {
         paddingRight: 12,
      }
   },
   selecticon: {
      color: theme.palette.primary.main
   },
   filterselecticon: {
      color: theme.palette.secondary.main,
      height: 20,
      width: 20,
      right: -2
   },
   linkbutton: {
      fontSize: '0.65rem',
      whiteSpace: 'nowrap',
      '&:hover': {
         background: 'transparent',
         textDecoration: 'underline'
      }
   },
   linkbuttonForm: {
      letterSpacing: '0.12em',
   },
   checkboxRoot: {
      padding: 0,
      '&:hover': {
         backgroundColor: 'transparent !important'
      }
   },
}));

export const TextInput = (props) => {

   const classes = useStyles();
   const {startAdornment, endAdornment, variant, tabIndex, inputProps={}, ...otherProps} = props;
   const isForm = variant === 'form';
   const isFilter = variant === 'filter';

   const helperTextProps = {
      classes: {
         root: classes.helperText
      }
   };

   const InputProps = {
      classes: {
         root: clsx(classes.inputRoot, {[classes.formInputRoot] : isForm, [classes.filterInputRoot] : isFilter}),
         notchedOutline: classes.notchedOutline,
         input: clsx(classes.input, {[classes.formInput] : isForm, [classes.filterInput] : isFilter})
      }
   };

   if (startAdornment) {
      InputProps.startAdornment =(
         <InputAdornment disableTypography={true} position='start'  classes={{positionStart: classes.adornmentPositionStart}}>
            {startAdornment}
         </InputAdornment>
      )
   }

   if (endAdornment) {
      InputProps.endAdornment = (
         <InputAdornment disableTypography={true} position='end'>
            {endAdornment}
         </InputAdornment>
      )
   }

   inputProps.maxLength = 256;

   if (tabIndex) {
      inputProps.tabIndex = parseInt(tabIndex);
   }

   return (
      <TextField 
         variant='outlined'
         autoComplete='off'
         style={{display: isForm? 'inline-flex' : 'block'}}
         InputProps={ InputProps }
         inputProps={ inputProps }
         FormHelperTextProps={ helperTextProps}
         InputLabelProps={{
            shrink: true,
         }}
         {...otherProps}
      />
   )
};

TextInput.propTypes = {
   startAdornment: PropTypes.node,
   variant: PropTypes.oneOf(['form', 'filter', 'standalone']),
   tabIndex: PropTypes.number
};

export const TextAreaInput = (props) => {

   const classes = useStyles();
   
   return (
      <TextField
         InputProps={{
            classes: {
               root: classes.textareaRoot,
               input: classes.textareaInput
            }
         }}
         className={classes.formControlRoot}
         multiline={true}
         variant='outlined'
         {...props}
      />
   )
};

export const SliderInput = (props) => {

   const classes = useStyles();

   return (
      <Slider 
         classes={{root: classes.sliderRoot, thumb: classes.sliderThumb, mark: classes.sliderMark, markActive: classes.sliderMark}}
         {...props}
      />
   )
};

export const StyledSelectFormControl  = withStyles(theme => ({
   root: {
      width: '100%',
      whiteSpace: 'nowrap',
      '& .MuiInput-underline:before' : {
         borderWidth: 0
      },
      '& .MuiInput-underline:after': {
         borderWidth: 0
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
         borderWidth: 0
      }
   },
}))(FormControl);

export const StyledFilterSelect = (props) => {

   const classes = useStyles();

   return (
      <Select
         classes={{select: clsx(classes.select, classes.filterselect), icon: clsx(classes.selecticon, classes.filterselecticon)}}
         MenuProps={{PopoverClasses:{paper: classes.selectPaper}}}
         {...props} 
      / >
   )
};

export const StyledSelect = (props) => {

   const classes = useStyles();

   return (
      <Select
         classes={{select: classes.select, icon: classes.selecticon}}
         MenuProps={{PopoverClasses:{paper: classes.selectPaper}}}
         {...props} 
      / >
   )
};

export const SearchAdornment = (props) => {

   const {color='gray'} = props;
   const classes = useStyles();
   return (<SearchIcon className={clsx(classes.searchIcon, {[classes.searchIconPrimary]: color==='primary'})} {...props} fontSize='small' />);

};

export const StyledMenuItem = withStyles(theme => ({
   root: {
      fontSize: '0.8rem',
      color: theme.palette.text.textInput,
      margin: '0 4px',
   },
}))(MenuItem);

export const StyledListSubheader = withStyles(theme => ({
   root: {
      fontSize: '0.65rem',
      color: theme.palette.text.popper.header,
      padding: '4px 0 2px 0',
      lineHeight: 1,
      margin: '4px',
      marginTop: 8,
      textTransform: 'uppercase',
      letterSpacing: '0.07em',
      fontWeight: 800,
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.border.main,
   },
}))(ListSubheader);

export const StyledSecondaryTooltip = withStyles(theme => ({
   tooltip: {
      backgroundColor: fade(theme.palette.background.tooltip.secondary, 0.95),
      color: theme.palette.common.white,
      letterSpacing: '0.03em',
      fontWeight: 'normal',
      fontSize: '0.7rem'
   },
}))(Tooltip);

export const StyledTooltip = withStyles(theme => ({
   tooltip: {
      backgroundColor: fade(theme.palette.background.tooltip.primary, 0.95),
      color: theme.palette.common.white,
      letterSpacing: '0.03em',
      fontWeight: 'normal',
      fontSize: '0.7rem'
   },
}))(Tooltip);

export const InlineErrorTooltip = withStyles(theme => ({
   tooltip: {
      backgroundColor: fade('#eaeaea', 0.9),
      color: theme.palette.error.dark,
      letterSpacing: '0.03em',
      fontWeight: 'normal',
      fontSize: '0.7rem'
   },
   arrow: {
      color: fade('#eaeaea', 0.9),
   },
   popper: {
      top: '-10px !important'
   }
}))(Tooltip);


export const StyledCheck = props => {
   const classes = useStyles();

   return (
      <Checkbox
         classes={{root: classes.checkboxRoot}}
         size='small' 
         disableRipple 
         disableFocusRipple
         icon={<StyledCheckIcon />}
         checkedIcon={<StyledCheckedIcon />} 
         {...props} 
      / >
   )
}

export const StyledCheckIcon = withStyles(theme => ({
   root: {
      fontSize: '0.8rem',
   }
}))(CheckBoxOutlineBlankIcon);

export const StyledCheckedIcon = withStyles(theme => ({
   root: {
      fontSize: '0.8rem',
   }
}))(CheckBoxIcon);

export const ActionButton = withStyles(theme => ({
   root: {
      minWidth: 40,
      lineHeight: 1,
      padding: '6px 10px',
      fontSize: '0.65rem',
      color: theme.palette.selected.secondary,
      backgroundColor: theme.palette.background.button.secondary
   },
   label: {
      whiteSpace: 'nowrap'
   }
}))(Button);

export const FormButton = withStyles(theme => ({
   root: {
      fontSize: '0.6rem',
      color: theme.palette.text.selected.primary,
      backgroundColor: theme.palette.background.button.primary,
      borderColor: theme.palette.border.primary,
      letterSpacing: '0.12em',
      '&:hover': {
         color: theme.palette.primary.main
      }
   },
}))(Button);

export const LinkButton = (props) => {

   const classes = useStyles();
   const {label, onClick, variant='inline', color='secondary', ...otherProps} = props;

   return (
      <Button className={clsx(classes.linkbutton, {[classes.linkbuttonForm] : variant==='form'})} disableFocusRipple onClick={onClick} color={color} {...otherProps}>{label}</Button>
   )
};

LinkButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(['inline', 'form']),
    color: PropTypes.oneOf(['primary', 'secondary'])
};
