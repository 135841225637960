/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';

import {TextInput, StyledCheck, StyledCheckIcon, StyledCheckedIcon, StyledMenuItem, StyledSelect, StyledSelectFormControl} from 'components/Controls';

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';

import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import { Field, getIn } from 'formik';

const useStyles = makeStyles(theme => ({
   formControl: {
     marginTop: 6,
     marginBottom: 6,
   },
 }));

const StyledInputLabel = withStyles(theme => ({
  root: {
     fontSize: '1.1rem',
  },
}))(InputLabel);

const HelperText = withStyles(theme => ({
   root: {
      fontSize: '0.7rem',
      marginTop: 4
   },
}))(FormHelperText);

export const RenderTextField = ({
  field,
  form: { touched, errors, handleChange },
  normalizer,
  label,
  tooltip,
  inputProps,
  ...others
}) => {
  // const classes = useStyles();
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);

  return (
    <FormControl error={touch && error != null} {...others}>
      {tooltip && (
        <Tooltip title={tooltip}>
          <StyledInputLabel shrink>
            {label}
            <InfoOutlinedIcon style={{ verticalAlign: 'middle' }} color='action' />
          </StyledInputLabel>
        </Tooltip>
      )}
      {!tooltip && <StyledInputLabel shrink>{label}</StyledInputLabel>}
      <TextInput
        name={field.name}
        value={field.value}
        variant='form'
        error={touch && error !== null && error !== undefined}
        onChange={e => {
          if (normalizer) {
            handleChange(field.name)(normalizer(e.target.value));
          } else {
            field.onChange(e);
          }
        }}
        onBlur={field.onBlur}
        {...inputProps}
      />
      {touch && error && <HelperText>{error}</HelperText>}
    </FormControl>
  );

};

export const FormTextField = ({ name, type, placeholder, helperText, startAdornment, ...others }) => {
  const classes = useStyles();
  return (
    <Field
      name={name}
      component={RenderTextField}
      className={classes.formControl}
      inputProps={{ type, placeholder, helperText, startAdornment }}
      {...others}
    />
  );
};

export const RenderCheckbox = forwardRef(function RenderCheckbox(props, ref) {
  const {
    field,
    // form: { touched, errors },
    label,
    ...others
  } = props;
  return (
    <FormControlLabel
      control={
        <StyledCheck
          checked={field.value}
          value={field.name}
          name={field.name}
          onChange={field.onChange}
          color='primary'
          ref={ref}
          icon={<StyledCheckIcon />}
          checkedIcon={<StyledCheckedIcon />} 
        />
      }
      label={label}
      {...others}
    />
  );
});
export const FormCheckbox = ({ name, ...others }) => (
  <Field name={name} component={RenderCheckbox} {...others} />
);

export const RenderFormSelect = ({
  field,
  form: { touched, errors },
  label,
  tooltip,
  children,
  dataSource,
  onChange,
  ...others
}) => {
  const classes = useStyles();
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);
  const myChildren =
    dataSource && dataSource.length > 0
      ? dataSource.map(o => {
          return (
            <StyledMenuItem key={o.id} value={o.id}>
              {o.name}
            </StyledMenuItem>
          );
        })
      : children;
  return (
    <StyledSelectFormControl error={touch && error != null} className={classes.formControl} {...others}>
      {tooltip && (
        <Tooltip title={tooltip}>
          <StyledInputLabel shrink>
            {label}
            <InfoOutlinedIcon style={{ verticalAlign: 'middle' }} color='action' />
          </StyledInputLabel>
        </Tooltip>
      )}
      {!tooltip && label && <StyledInputLabel shrink>{label}</StyledInputLabel>}
      <StyledSelect
        placeholder={label}
        value={field.value}
        onChange={e => {
          if (onChange) {
            onChange(e);
          }
          field.onChange(e);
        }}
        children={myChildren}
        name={field.name}
        {...others}
      />
      {touch && error && <HelperText>{error}</HelperText>}
    </StyledSelectFormControl>
  );
};

export const FormSelect = ({ name, dataSource, ...others }) => (
  <Field name={name} component={RenderFormSelect} dataSource={dataSource} {...others} />
);

export const RenderMultiSelect = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  children,
  dataSource,
  ...others
}) => {
  const classes = useStyles();
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);
  return (
    <FormControl error={touch && error != null} className={classes.formControl} {...others}>
      <StyledInputLabel shrink>{label}</StyledInputLabel>
      {dataSource && (
        <Select
          multiple
          value={field.value || []}
          onChange={event => {
            setFieldValue(field.name, event.target.value);
          }}
          renderValue={selected => {
            return dataSource
              .filter(o => selected.includes(o.id))
              .map(o => o.name)
              .join(', ');
          }}
        >
          {dataSource.map(option => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox checked={field.value && field.value.includes(option.id)} />
              <ListItemText primary={option.name} />
            </MenuItem>
          ))}
        </Select>
      )}
      {touch && error && <HelperText>{error}</HelperText>}
    </FormControl>
  );
};
export const FormMultiSelect = ({ name, dataSource, ...others }) => (
  <Field name={name} component={RenderMultiSelect} dataSource={dataSource} {...others} />
);

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);
export const RenderFileInput = ({
  field,
  form: { touched, errors, handleChange, handleBlur },
  ...others
}) => {
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);
  return (
    <TextField
      name={field.name}
      onChange={adaptFileEventToValue(handleChange(field.name))}
      onBlur={adaptFileEventToValue(handleBlur(field.name))}
      type='file'
      error={touch && error != null}
      helperText={touch && error}
      {...others}
    />
  );
};

export const FormFileInput = ({ name, ...others }) => (
  <Field name={name} component={RenderFileInput} {...others} />
);
