import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import VizSensor from "react-visibility-sensor";

import PublicHeader from "components/PublicHeader";
import PublicNewAccount from "components/PublicNewAccount";
import PublicTypography from "components/PublicTypography";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import appImage from "assets/img/app.png";
import monitorImage from "assets/img/monitor.png";
import searchImage from "assets/img/search.png";
import detailImage from "assets/img/detail.png";
import comparableImage from "assets/img/comparable.png";
import sipcImage from "assets/img/sipc_logo.png";

import "assets/css/public.scss";

const useStyles = makeStyles((theme) => ({
  appBarRoot: {
    background: theme.palette.background.header,
  },
  intro: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    minHeight: "9rem",
    maxHeight: "30%",
    flexGrow: 1,
  },
  introImage: {
    margin: "auto",
    position: "relative",
  },
  appImageWrapper: {
    backgroundPosition: "0 0",
    backgroundImage: "url(" + monitorImage + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  introTextWrapper: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column nowrap",
    minHeight: "inherit",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  finalText: {
    opacity: 0,
    animation: "fadein 500ms ease-in",
    animationFillMode: "forwards",
  },
  signupButton: {
    opacity: 0,
    animation: "fadein 800ms ease-in 500ms",
    animationFillMode: "forwards",
  },
  appImageTransition: {
    opacity: 0,
    animation: "fadein 1000ms ease-in",
    animationFillMode: "forwards",
  },
  features: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      ["@media screen and (min-height: 750px)"]: {
        // eslint-disable-line no-useless-computed-key
        flexWrap: "wrap",
        paddingBottom: theme.spacing(0),
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      paddingBottom: theme.spacing(0),
    },
    ["@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)"]: {
      // eslint-disable-line no-useless-computed-key
      flexWrap: "wrap",
      paddingBottom: theme.spacing(0),
    },
  },
  featurenav: {
    [theme.breakpoints.up("md")]: {
      ["@media screen and (max-height:750px)"]: {
        // eslint-disable-line no-useless-computed-key
        width: "25%",
      },
    },
  },
  featureGridItem: {
    [theme.breakpoints.up("md")]: {
      ["@media screen and (max-height: 750px)"]: {
        // eslint-disable-line no-useless-computed-key
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
  },
  search: {
    opacity: 1,
    animation: "fadein 1500ms",
    animationFillMode: "forwards",
  },
  comp: {
    opacity: 1,
    animation: "fadein2 1500ms",
    animationFillMode: "forwards",
  },
  detail: {
    opacity: 1,
    animation: "fadein3 1500ms",
    animationFillMode: "forwards",
  },
  disclaimer: {
    fontSize: "1.0rem",
    color: "#fff",
    "&:visited": {
      color: "#fff",
    },
  },
}));

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

export const SignupButton = withStyles((theme) => ({
  root: {
    color: theme.palette.text.selected.primary,
    "&:hover": {
      color: theme.palette.text.selected.primary,
    },
  },
}))(Button);

export const PublicPaper = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(Paper);

export const ButtonPaper = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    textAlign: "center",
    height: "100%",
    background: "white",
    cursor: "pointer",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
  },
}))(Paper);

export const BulletPaper = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    textAlign: "left",
    height: "100%",
    background: "white",
    borderRadius: 10,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
  },
}))(Paper);

export const PublicAppbar = (props) => {
  const { elevation, ...otherProps } = props;

  const classes = useStyles();

  return (
    <AppBar elevation={elevation} classes={{ root: classes.appBarRoot }}>
      <Toolbar>
        <PublicHeader toolbar={elevation > 0} {...otherProps} />
      </Toolbar>
    </AppBar>
  );
};

let slideShowInterval = null;
const SLIDES = { 0: "search", 1: "comp", 2: "detail" };

export default function PublicLayout(props) {
  const classes = useStyles();
  const [loaded, setLoaded] = React.useState(false);
  const [selected, setSelected] = React.useState("search");
  const [openModal, setOpenModal] = React.useState(false);
  const slideIndex = React.useRef(0);

  React.useLayoutEffect(() => {
    setLoaded(true);
  }, []);

  const handleClick = (slide) => {
    slideIndex.current = null;
    setSelected(slide);
  };

  const incrementSlide = () => {
    if (slideIndex.current === null) {
      clearInterval(slideShowInterval);
    } else {
      let index = slideIndex.current;
      index = index === 2 ? 0 : index + 1;
      slideIndex.current = index;

      const newSlide = SLIDES[index];
      setSelected(newSlide);
    }
  };

  const updateSlideshow = (play) => {
    if (slideShowInterval) {
      clearInterval(slideShowInterval);
    }

    if (play) {
      slideShowInterval = window.setInterval(incrementSlide, 5000);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <ElevationScroll {...props}>
        <PublicAppbar onClickNewAccount={(e) => setOpenModal(true)} />
      </ElevationScroll>
      <Toolbar />
      <div
        style={{
          background: "#ffffff",
          minHeight: "calc(100vh - 64px)",
          display: "flex",
        }}
      >
        <Container>
          <Box
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <Box className={classes.intro}>
              <div className={classes.introTextWrapper}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <PublicTypography
                    style={{ marginBottom: ".8rem" }}
                    variant="h1"
                    className={clsx({ [classes.finalText]: loaded })}
                  >
                    The bond platform built to meet the specific investment
                    needs of community financial institutions
                  </PublicTypography>
                  <SignupButton
                    onClick={(e) => setOpenModal(true)}
                    color="secondary"
                    variant="contained"
                    className={clsx({ [classes.signupButton]: loaded })}
                  >
                    Sign Up For Free
                  </SignupButton>
                </div>
              </div>
            </Box>
            <Box py={2} className={classes.introImage}>
              <div
                className={classes.appImageWrapper}
                style={{ maxWidth: 950, margin: "auto" }}
              >
                <div className={clsx({ [classes.appImageTransition]: true })}>
                  <img
                    style={{ width: "100%" }}
                    src={appImage}
                    alt="QwickBonds"
                  />
                </div>
              </div>
            </Box>
          </Box>
        </Container>
      </div>
      <Container>
        <Box py={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <BulletPaper className={classes.paper} elevation={2}>
                <PublicTypography variant="h4">
                  <PublicTypography
                    color="primary"
                    component="span"
                    variant="h4"
                  >
                    Take control
                  </PublicTypography>{" "}
                  of your investing process
                </PublicTypography>
              </BulletPaper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <BulletPaper className={classes.paper} elevation={2}>
                <PublicTypography variant="h4">
                  <PublicTypography
                    color="primary"
                    component="span"
                    variant="h4"
                  >
                    Increase
                  </PublicTypography>{" "}
                  your ability to access additional bond offerings
                </PublicTypography>
              </BulletPaper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <BulletPaper className={classes.paper} elevation={2}>
                <PublicTypography variant="h4">
                  <PublicTypography
                    color="primary"
                    component="span"
                    variant="h4"
                  >
                    Improve
                  </PublicTypography>{" "}
                  bids in selling Bonds through RFQ
                </PublicTypography>
              </BulletPaper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <BulletPaper className={classes.paper} elevation={2}>
                <PublicTypography variant="h4">
                  <PublicTypography
                    color="primary"
                    component="span"
                    variant="h4"
                  >
                    Transparency
                  </PublicTypography>{" "}
                  of pricing for CUSIPS offered in the market
                </PublicTypography>
              </BulletPaper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <BulletPaper className={classes.paper} elevation={2}>
                <PublicTypography variant="h4">
                  <PublicTypography
                    color="primary"
                    component="span"
                    variant="h4"
                  >
                    Access
                  </PublicTypography>{" "}
                  bond data and Credit Research reports direct from the QB
                  platform
                </PublicTypography>
              </BulletPaper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <BulletPaper className={classes.paper} elevation={2}>
                <PublicTypography variant="h4">
                  <PublicTypography
                    color="primary"
                    component="span"
                    variant="h4"
                  >
                    Utilize
                  </PublicTypography>{" "}
                  the QB Help Desk as a third party to research and guide you
                  through the investing process
                </PublicTypography>
              </BulletPaper>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <div style={{ background: "#fafafa" }}>
        <Container>
          <Box>
            <Box pt={6} pb={4}>
              <PublicTypography variant="h2" color="secondary" gutterBottom>
                QB makes it easy to access thousands of real-time bond
                offerings, compare pricing and increase liquidity.
              </PublicTypography>
              <PublicTypography variant="h3">
                Use the information you find on QB to make and justify
                decisions, guide conversations with your broker, or place an
                order through the QB trading platform.
              </PublicTypography>
            </Box>
            <Box pb={0} className={classes.features}>
              <Box>
                <VizSensor
                  partialVisibility
                  onChange={(isVisible) => {
                    updateSlideshow(isVisible, selected);
                  }}
                >
                  <div
                    className={classes.appImageWrapper}
                    style={{ maxWidth: 1043 }}
                  >
                    <img
                      className={clsx(classes.image, {
                        [classes.search]: selected === "search",
                        [classes.comp]: selected === "comp",
                        [classes.detail]: selected === "detail",
                      })}
                      style={{ width: "100%" }}
                      src={
                        selected === "search"
                          ? searchImage
                          : selected === "comp"
                          ? comparableImage
                          : detailImage
                      }
                      alt="QwickBonds"
                    />
                  </div>
                </VizSensor>
              </Box>
              <Box p={3} className={classes.featurenav}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className={classes.featureGridItem}
                  >
                    <ButtonPaper
                      onClick={(e) => handleClick("search")}
                      className={classes.paper}
                      elevation={selected === "search" ? 8 : 2}
                    >
                      <PublicTypography
                        variant="overline"
                        gutterBottom
                        color="primary"
                      >
                        Dynamic Search
                      </PublicTypography>
                      <PublicTypography variant="body1">
                        Search and filter on a range of bond characteristics
                      </PublicTypography>
                    </ButtonPaper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className={classes.featureGridItem}
                  >
                    <ButtonPaper
                      onClick={(e) => handleClick("comp")}
                      className={classes.paper}
                      elevation={selected === "comp" ? 8 : 2}
                    >
                      <PublicTypography
                        variant="overline"
                        gutterBottom
                        color="primary"
                      >
                        Comparable Bonds
                      </PublicTypography>
                      <PublicTypography variant="body1">
                        Quickly find bonds with similar characteristics of the
                        bond you searched
                      </PublicTypography>
                    </ButtonPaper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className={classes.featureGridItem}
                  >
                    <ButtonPaper
                      onClick={(e) => handleClick("detail")}
                      className={classes.paper}
                      elevation={selected === "detail" ? 8 : 2}
                    >
                      <PublicTypography
                        variant="overline"
                        gutterBottom
                        color="primary"
                      >
                        Get the Details
                      </PublicTypography>
                      <PublicTypography variant="body1">
                        Fully understand the characteristics of a bond before
                        buying
                      </PublicTypography>
                    </ButtonPaper>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Container>
      </div>

      <div style={{ background: "#ffffff" }}>
        <Container>
          <Box pt={6} pb={3}>
            <PublicTypography variant="h2" color="secondary" gutterBottom>
              Banks and Credit Unions Receive Full Access for Free
            </PublicTypography>
            <PublicTypography variant="h3">
              Valuable information that would normally cost thousands of dollars
              to access is at your fingertips on QB for free.
            </PublicTypography>
          </Box>
          <Box pt={3} pb={6}>
            <div style={{ textAlign: "center" }}>
              <SignupButton
                onClick={(e) => setOpenModal(true)}
                color="secondary"
                variant="contained"
              >
                Sign Up For Free
              </SignupButton>
            </div>
          </Box>
        </Container>
      </div>
      <div style={{ background: "#fafafa" }}>
        <Container style={{ display: "flex", flexDirection: "column" }}>
          <Box py={6}>
            <PublicTypography style={{ marginBottom: "0.8rem" }}>
              QwickBonds is an electronic trading and research platform powered by MCAP Technologies LLC.
              QwickBond’s broker affiliate MCAP LLC and MCAP Technologies LLC are wholly owned subsidiaries
               of MCAP Inc. (together "MCAP").  MCAP LLC is a registered broker-dealer and member of FINRA,
               the MSRB and the SIPC. Securities are offered through MCAP LLC and are not offered by QwickBonds,
               MCAP Technologies, MCAP Inc. or QwickRate. MCAP is a partner company of QwickRate.
            </PublicTypography>
          </Box>
        </Container>
      </div>
      <div style={{ background: "#3971a4" }}>
        <Container>
          <Box py={3}>
            <Box pb={3} textAlign="center">
              <a
                className={classes.disclaimer}
                href={`${process.env.PUBLIC_URL}/documents/QwickBonds_Disclaimer.pdf`}
                title="Important Information and Disclaimers"
                target="_blank"
                rel="noopener noreferrer"
              >
                Important Information and Disclaimers
              </a>
            </Box>
            <Box pb={3} textAlign="center">
              <PublicTypography style={{ fontSize: "0.75rem" }}>
                Check the background of this Firm and/or investment
                professionals on{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://brokercheck.finra.org/firm/summary/139515"
                >
                  FINRA's BrokerCheck.
                </a>
              </PublicTypography>
<PublicTypography style={{ fontSize: "0.75rem" }}>
                {" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.finra.org/investors/bonds"
                >
                  FINRA
                </a>{" "}
                /{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.sipc.org/"
                >
                  SIPC
                </a>{" "}
                /{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.msrb.org/"
                >
                  MSRB.
                </a>
              </PublicTypography>
            </Box>
            <Box pb={3} display="flex" justifyContent="center" flexGrow={1}>
              <img
                src={sipcImage}
                alt="Securities Investor Protection Corporation"
              />
            </Box>
            <Box textAlign="center">
              <PublicTypography style={{ fontSize: "0.7rem" }}>
                &#xA9;{new Date().getFullYear()} MCAP Inc.
              </PublicTypography>
              <PublicTypography style={{ fontSize: "0.7rem" }}>
                Warning: This is a private network and unauthorized access or
                use is not permitted and constitutes a crime punishable by
                Federal law.
              </PublicTypography>
            </Box>
          </Box>
        </Container>
      </div>
      <PublicNewAccount open={openModal} onClose={(e) => setOpenModal(false)} />
    </div>
  );
}
