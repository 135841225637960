
import Typography from '@material-ui/core/Typography';
import {withStyles } from '@material-ui/core/styles';

const QBTypography = withStyles(theme => ({

   h1: {
      lineHeight: 1.5,
      fontSize: '1.6rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.3rem',
      },
      [theme.breakpoints.down('xs')]: {
         fontSize: '1.1rem',
       },
   },
   h2: {
      lineHeight: 1,
      fontSize: '1.4rem',
      marginBottom: '1.2rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2rem',
      },
   },
   h3: {
      fontSize: '0.9rem',
      fontWeight: 400,
      lineHeight: 1.3,
      letterSpacing: '-0.01562em'
   },
   h4: {
      fontSize: '1.2rem',
      fontWeight: 300,
      '& span': {
         fontWeight: 400
      },
      [theme.breakpoints.down('sm')]: {
         fontSize: '1.1rem',
      },
   },
   subtitle1: {
      lineHeight: 1,
   },
   body1: {
      fontSize: '.85rem',
      lineHeight: 1.4
   },
   overline: {
      lineHeight: 1.2
   }
}))(Typography);

export default QBTypography;