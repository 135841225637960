import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import * as Messages from 'commons/messages';
import {FormTextField, FormSelect} from 'components/forms/FormikFields';
import PublicTypography from 'components/PublicTypography';

import Button from '@material-ui/core/Button';

import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';

import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
   adornment: {
      fontSize: '1.0rem',
      color: theme.palette.primary.main
   },
   form: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
   },
   fieldset: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(3)
   },
   error: {
      marginBottom: theme.spacing(1),
      textAlign: 'center',
      fontSize: '0.75rem'
   }
}));

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const NewAccountSchema = Yup.object().shape({
   firstname: Yup.string()
      .required(Messages.MESSAGE.VALIDATION.REQUIRED),
   lastname: Yup.string()
      .required(Messages.MESSAGE.VALIDATION.REQUIRED),
   email: Yup.string()
      .email(Messages.MESSAGE.VALIDATION.INVALID_FORMAT)
      .required(Messages.MESSAGE.VALIDATION.REQUIRED),
   phonenumber: Yup.string()
      .matches(phoneRegExp, Messages.MESSAGE.VALIDATION.INVALID_FORMAT)
      .required(Messages.MESSAGE.VALIDATION.REQUIRED),
   institutiontype: Yup.number()
      .min(0, Messages.MESSAGE.VALIDATION.REQUIRED),
   institutiontypeother: Yup.string()
      .when('institutiontype', {
         is: 6,
         then: Yup.string().required(Messages.MESSAGE.VALIDATION.REQUIRED)
      })
});

const httpService = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
   timeout: 20000
});

const refEndpoint = `/reference/newusersignupinvestortypes`;


const NewAccountForm = (props) => {

   const classes = useStyles();
   const {onSuccess} = props;
   const [error, setError] = React.useState();
   const [institutionOptions, setInstitutionOptions] = React.useState([{id: -1, name: 'Select one'}]);

   React.useEffect (() => {

      httpService.get(refEndpoint).then(response => {
         if (response.status === 200 && response.data) {
            const options = response.data.map(o => { return {id: Number(o.id), name: o.description}});
            options.unshift({id: -1, name: 'Select one'})
            setInstitutionOptions(options);
         } else {
            console.error('Received no data in response for newusersignupinvestortypes');
         }
      })                  
      .catch(error => {
         console.error(error);
      });

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div>
         <PublicTypography className={classes.error} color='error'>{error}</PublicTypography>
         <Formik
            initialValues={{ firstname: '', lastname: '', email: '', phonenumber: '', institutiontype: '-1', institutiontypeother: '' }}
            validationSchema={NewAccountSchema}
            onSubmit={values => {

               setError('');

               const data = {
                  firstName: values.firstname,
                  lastName: values.lastname,
                  email: values.email,
                  phone: values.phonenumber
               }

               if (values.institutiontype === 6) {
                  data.investorTypeOtherValue = values.institutiontypeother;
               } else {
                  data.investorTypeId = values.institutiontype;
               }

               httpService.post(`/newuser/`, data)
               .then(response => {
                  onSuccess();
               })
               .catch(e => {
                  setError('There has been an error and your submission was not received.  Please try again or call 844.636.2663 for assistance.');
               });

            }}
         >
            {({ errors, touched, values }) => (
                  <Form className={classes.form}>
                     <div className={classes.fieldset}>
                        <FormTextField placeholder={Messages.FORM_LABEL.FIRSTNAME} name='firstname' autoFocus={true} startAdornment={(<AccountCircleOutlinedIcon className={classes.adornment} />)} />
                        <FormTextField placeholder={Messages.FORM_LABEL.LASTNAME} name='lastname' startAdornment={(<AccountCircleOutlinedIcon className={classes.adornment} />)} />
                        <FormTextField placeholder={Messages.FORM_LABEL.EMAIL} name='email' type='email' startAdornment={(<EmailOutlinedIcon className={classes.adornment} />)} />
                        <FormTextField placeholder={Messages.FORM_LABEL.PHONE} name='phonenumber' type='tel' startAdornment={(<PhoneOutlinedIcon className={classes.adornment} />)} />
                        <FormSelect label={Messages.FORM_LABEL.INSTITUTION} dataSource={institutionOptions} name='institutiontype' />
                        {values.institutiontype === 6 && 
                           <FormTextField placeholder={Messages.FORM_LABEL.INSTITUTION_OTHER} name='institutiontypeother' />
                        }                 
                     </div>
                     <Button type='submit' color='primary' variant='outlined'>{Messages.FORM_LABEL.SUBMIT}</Button>
                  </Form>
            )}
         </Formik>
      </div> 
   )
};

NewAccountForm.propTypes = {
   onSuccess: PropTypes.func.isRequired
}

export default NewAccountForm;