import { createMuiTheme} from '@material-ui/core/styles';

export const lightTheme = {
   palette: {
      type: 'light',
      primary: {
         main: '#00a0da', //'#00b0f0',
         dark: '#099cd2'
      },
      secondary: {
         main: '#13b71b', //'#00c109',
         dark: '#069e0d'
      },
      tertiary: {
         main: '#00c109', 
      },
      error: {
         main: '#d00c0c'
      },
      border: {
         main: 'rgba(129, 129, 129, 0.1)',
         highlight: 'rgba(30, 150, 36, 0.4)',
         textInput: 'rgba(129, 129, 129, 0.4)',
         disabled: 'rgba(200, 200, 200, 0.6)',
         subtle: 'rgba(200, 200, 200, 0.3)',
         drawer: '#4ca1c0',
         primary: 'rgba(0, 143, 195, 0.6)',
         secondary: 'rgba(30, 150, 36, 0.5)'
      },
      logo: {
         main: '#00b0f0',
         dark: '#006185'
      },
      text: {
         primary: 'rgba(10, 10, 10, 0.95)',
         secondary: 'rgba(100, 100, 100, 0.95)',
         tertiary: 'rgba(90, 90, 90, 0.95)',
         disabled: 'rgba(200, 200, 200, 0.95)',
         textInput: 'rgba(20, 20, 20, 0.95)',
         info: '#e6cb02',
         warning: '#ff8d00',
         snackbar: 'rgba(10, 10, 10, 0.95)',
         unselected: {
            primary: 'rgba(0, 143, 195, 0.95)',
            secondary: 'rgba(0, 193, 9, 0.87)',
            disabled: 'rgba(255, 255, 255, 1.0)'
         },
         selected: {
            primary: 'rgba(255, 255, 255, 0.87)',
            secondary: 'rgba(255, 255, 255, 0.87)'
         },
         popper: {
            header: 'rgba(90, 90, 90, 0.95)',
            text: 'rgba(120, 120, 120, 0.95)'
         }
      },
      background: {
         default: '#f3f3f3',
         drawer: 'none',
         paperButton: '#fff',
         paper: '#fafafa',
         popperPaper: '#f6f6f6', //'#484848',
         textInput: 'rgba(0, 0, 0, 0.87)',
         snackbar: '#fff',
         shade: 'rgba(255, 255, 255, 0.05)',
         hover: 'rgba(0, 0, 0, 0.04)',
         menuhover: 'rgba(255, 255, 255, 0.2)',
         selected: 'rgba(0, 0, 0, 0.16)',
         subtle: 'rgba(200, 200, 200, 0.03)',
         header: '#fff',
         badge: '#13b71b',
         tooltip: {
            primary: '#007CAB',
            secondary: '#027b07'
         },
         button: {
            primary: 'rgba(0, 143, 195, 0.68)',
            secondary: 'rgba(6, 158, 13, 0.62)',
            buy: 'rgba(33, 96, 152, 0.88)'
         }
      },
   },
   shape: {
      borderRadius: 5
   },
};

export const LightTheme = createMuiTheme(lightTheme);