
import React from 'react';
import PropTypes from 'prop-types';

import QBTypography from 'components/PublicTypography';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CancelPresentation';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {withStyles, makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
   modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',     
   },
   modalpaper: {
      padding: 0,
      outline: 0,
      backgroundColor: theme.palette.background.paper,
      maxWidth: '95vw',
      maxHeight: '95vh',
      overflow: 'hidden'
   },
   toolbar: {
      display: 'flex',
   },
   toolbarSection: {
      textAlign: 'center',
      paddingTop: 10,
      flexGrow: 0,
      '&:first-child': {
         textAlign: 'left',
         flexGrow: 1
      },
      '&:last-child': {
         textAlign: 'right',
         flexGrow: 1,
         padding: 3,
      }
   },
   icon: {
      padding: 0,
      fontSize: '0.9rem'
   },
   contentWrapper: {
      padding: theme.spacing(2),
      paddingTop: 8,
      height: 'calc(100% - 26px)'
   },
   unclosableContentWrapper: {
      padding: theme.spacing(2),
      height: '100%'
   }
}));

export const Div = ({style, ...otherProps}) => (<div style={{...{height: '100%', width: '100%'}, ...style}} {...otherProps} />);

export const TablePaper = withStyles(theme => ({
   root: {
      padding: theme.padding.paper,
      outline: 0,
      height: '100%'
   },    
}))(Paper);


export const StyledPaper = withStyles(theme => ({
   root: {
      fontSize: '.75rem',
      backgroundColor: theme.palette.background.popperPaper,
      padding: theme.spacing(1),
      color: theme.palette.text.popper.text,
      maxHeight: '98vh',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      overflowX: 'hidden'
   }   
}))(Paper);




export const SearchBox = withStyles(theme => ({
   root: {
      border: '1px solid',
      borderColor: theme.palette.border.main,
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.background.subtle,
      padding: 10,
      color: theme.palette.text.primary,
      textAlign: 'center',
      margin: 'auto'
   }   
}))(Box);

export const ClosableModal = (props) => {

   const {children, onClose, open, header, HeaderProps, ...otherProps} = props;
   const classes = useStyles();

   return (
      <Modal
         className={classes.modal}
         open={open}
         onClose={onClose}
         closeAfterTransition
         BackdropComponent={Backdrop}
         BackdropProps={{
            timeout: 200
         }}
         {...otherProps}>
         <Fade in={open}>
            <Paper elevation={8} className={classes.modalpaper}>
               <Div>
                  <div className={classes.toolbar}>
                     <div className={classes.toolbarSection}></div>
                     <div className={classes.toolbarSection}>
                        <QBTypography variant='h3' {...HeaderProps}>
                           {header}
                        </QBTypography>
                     </div>
                     <div className={classes.toolbarSection}>
                        <IconButton color='primary' size='small' onClick={onClose}>
                           <CloseIcon className={classes.icon}  />
                        </IconButton>
                     </div>
                  </div>
                  <div className={classes.contentWrapper}>
                     {children}
                  </div>
               </Div>
            </Paper>
         </Fade>
      </Modal>
   );
};

ClosableModal.propTypes = {
   children: PropTypes.node.isRequired,
   onClose: PropTypes.func.isRequired,
   open: PropTypes.bool.isRequired,
   header: PropTypes.node,
   HeaderProps: PropTypes.object
};

export const UnclosableModal = (props) => {

   const {children, open, ...otherProps} = props;
   const classes = useStyles();

   return (
      <Modal
         className={classes.modal}
         open={open}
         disableBackdropClick
         closeAfterTransition
         BackdropComponent={Backdrop}
         BackdropProps={{
            timeout: 200,
         }}
         {...otherProps}>
         <Fade in={open}>
            <Paper elevation={8} className={classes.modalpaper}>
               <div className={classes.unclosableContentWrapper}>
                  {children}
               </div>
            </Paper>
         </Fade>
      </Modal>
   );
};

UnclosableModal.propTypes = {
   children: PropTypes.node.isRequired,
   open: PropTypes.bool.isRequired
};