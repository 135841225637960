import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import * as Messages from 'commons/messages';
import PublicTypography from 'components/PublicTypography';
import NewAccountForm from 'components/NewAccountForm';
import {ClosableModal} from 'components/Containers';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles} from '@material-ui/core/styles';

import logo from 'assets/img/logo.png';


const useStyles = makeStyles(theme => ({
   root: {
      minWidth: 275,
      width: 275,
      display: 'flex',
      flexDirection: 'column',
      minHeight: 320,
      transition: theme.transitions.create('min-height', {
         easing: theme.transitions.easing.easeIn,
         duration: 200
      }),
   },
   header: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
      alignItems: 'center',
   }
}));


const PublicNewAccount = (props) => {

   const classes = useStyles();
   const {open, onClose} = props;
   const [status, setStatus] = React.useState('form');

   const closeModal = () => {
      setStatus('form');
      onClose();
   }

   return (
      <ClosableModal open={open} onClose={closeModal}>
         <div className={classes.root}>
            <div className={classes.header}>
               <img src={logo} alt="Logo" />
               <PublicTypography style={{marginTop: 5, fontStyle: 'italic'}} variant='body1'><span>Provided by MCAP Technologies LLC</span></PublicTypography>
            </div>     
            <CSSTransition mountOnEnter unmountOnExit onExited={e => setStatus('success')} appear timeout={{ enter: 500, exit: 100 }} classNames='transition-fade' in={status === 'form'}>                 
               <NewAccountForm onSuccess={e => setStatus('inter')} />
            </CSSTransition>
            <CSSTransition mountOnEnter appear timeout={{ enter: 500, exit: 100 }} classNames='transition-fade' in={status === 'success'}>                 
               <Box mt={2} display='flex' flexDirection='column' flexGrow={1}>
                  <Box flexGrow={1}>
                     <PublicTypography style={{marginBottom: 16}} color='textSecondary'>Thank you for inquiring about QwickBonds.  A Monroe Financial Partners representative will contact you to establish access to QwickBonds.</PublicTypography>
                     <PublicTypography color='textSecondary' >If you have an immediate need or would like to talk to our Help Desk please call <PublicTypography component='span' color='secondary'>844.636.2663.</PublicTypography></PublicTypography>
                  </Box>
                  <Box>
                     <Button style={{width: '100%'}} onClick={closeModal} color='primary' variant='outlined'>{Messages.FORM_LABEL.CLOSE}</Button>
                  </Box>
               </Box>               
            </CSSTransition>
         </div>
      </ClosableModal>
   )
};

PublicNewAccount.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired
};

export default PublicNewAccount;